.tech__tile {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.technologies{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2em 2em;
    flex-direction: column;
}

.technology {
    width: 100%;
    max-width: 900px;
    margin-bottom: 3em;
}
.proj_btn {
    background-color: var(--clr-main);
    color: white;
    border: none;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1em;
    cursor: pointer;
    transition-duration: 0.4s;
    margin-bottom: 2em;
}
.tech_btn {
    background-color: var(--clr-main);
    color: white;
    border: none;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1em;
    cursor: pointer;
    transition-duration: 0.4s;
    margin-bottom: 2em;
}

.tech_btn:hover {
    opacity: 80%;
}