.logo {
    height: 2em;
}

.nav__list {
    display: flex;
    height: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 330px;
}

@media (max-width: 880px) {
    .nav__list {
        width: 330px;
    }
}


.nav__link {
    text-decoration: none;
    color: inherit;
}

header {
    display: flex;
    justify-content: space-between;
    padding: 1em;
}