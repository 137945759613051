.port__tile {
    display: flex;
    background-color: var(--clr-tile);
    flex-direction: column;
    align-items: center;
}

.port__title {
    color: var(--clr-main)
}

.port__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1em;
}

.proj {
    max-width: 18em;
    margin-bottom: 1em;
}

.proj img {
    max-width: 18em;
    width: 100%;
    max-height: 10em;
    height: 100%;
    object-fit: cover;
}

.port__btn{
    background-color: var(--clr-main);
    color: white;
    border: none;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1em;
    cursor: pointer;
    transition-duration: 0.4s;
    margin-bottom: 2em;
}

.port__btn:hover {
    opacity: 70%;
}


@media (max-width: 800px) {
    .port__grid {
        grid-template-columns: 1fr;
    }
}